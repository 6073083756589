import localAppMetadata from "../app_metadata_config";
const axios = require("axios").default;

const fetchAppMetadata = async () => {
  try {
    const headers = {
      access_token: process.env.REACT_APP_STACK_DELIVERY_KEY,
      api_key: process.env.REACT_APP_STACK_API_KEY,
    };
    const params = {
      locale: process.env.REACT_APP_STACK_LOCALE,
      environment: process.env.REACT_APP_STACK_ENV,
    };
    const urlParams = {
      content_type_uid: process.env.REACT_APP_CT_UID,
      entry_uid: process.env.REACT_APP_ENTRY_UID,
    };
    return axios({
      method: "GET",
      url: `https://cdn.contentstack.io/v3/content_types/${urlParams.content_type_uid}/entries/${urlParams.entry_uid}`,
      headers: headers,
      params: params,
    })
      .then((res) => {
        return res.data.entry;
      })
      .catch((err) => {
        console.log("error here", err);
        return localAppMetadata.default;
      });
  } catch (error) {
    console.error("Error while fetching data", error);
    return localAppMetadata.default;
  }
};

const checkValidApiKey = async (api_key, model) => {
  try {
    let url = "https://api.openai.com/v1/chat/completions";
    let data = {
      model,
      messages: [
        {
          role: "user",
          content: "write a word with c",
        },
      ],
      temperature: 0.75,
      max_tokens: 100,
    };
    await axios({
      method: "POST",
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${api_key}`,
      },
      data,
    });
    return {};
  } catch (error: any) {
    if (error.code === "ERR_BAD_REQUEST")
      return {
        errorMessage: "Invalid API Key Provided.",
      };
    else
      return {
        errorMessage: "Could not check with the OpenAI. Try again",
      };
  }
};

export { fetchAppMetadata, checkValidApiKey };
