import { AsyncLoader } from '@contentstack/venus-components'
import { ReactComponent as StopIcon } from "../../assets/stopbutton.svg"
import "./LoadingScreen.css"

const LoadingScreen = ({ message = 'Initializing your app...almost there!', showMessage = true, showStopGeneration = false }: any) => {
  return (
    <div className={`loading-container ${showMessage && 'short-container'}`}>
      <AsyncLoader color='#6c5ce7' />
      {showMessage && <div className='loading-description'>{message}</div>}
      {showStopGeneration && <div className='stop-generator' onClick={showStopGeneration}>
        <span><StopIcon /></span>
        <span className='stop-text'>
          Stop Generating
        </span>
      </div>}
    </div>
  )
}

export default LoadingScreen