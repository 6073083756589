import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import '@contentstack/venus-components/build/main.css'
import './index.css';
import App from "./containers/App/App";

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

