import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useAppLocation } from "../hooks/useAppLocation";
import { isEmpty } from "lodash";
import { fetchAppMetadata } from '../../utils/common'
import { EntryFieldExtensionContext } from "../contexts/entryFieldExtensionContext";
import { ChatGPTAppMetadataType } from "../types/AppMetadata";
import { useAppSdk } from "../hooks/useAppSdk";
import { AppConfigMissing } from "../../components/AppConfigMissing";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

export const EntryFieldExtensionProvider = ({ children }: any) => {
  const appSdk = useAppSdk()
  const [token, setToken] = useState<string>('')
  const [configMissing, setConfigMissing] = useState<boolean>(false);
  const [entryField, setEntryField] = useState<unknown>(null);
  const [appMetadata, setAppMetadata] = useState<ChatGPTAppMetadataType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [configLoading, setConfigLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { location } = useAppLocation();

  useEffect(() => {
    (async () => {
      // check if the data was loaded earlier or not
      if (isEmpty(entryField)) {
        setLoading(true);
        let fieldData = await location.field.getData();
        setEntryField(fieldData);
        setLoading(false);
      }
    })();
  }, [setLoading, setEntryField, location, entryField]);

  useEffect(() => {
    fetchAppMetadata().then((data) => {
      setAppMetadata(data)
    }).catch(() => {
      setErrorMessage('Failed to get the initial config.')
    })
  }, [])

  useEffect(() => {
    (async () => {
      if (!appSdk) return
      const appConfig = await appSdk.getConfig();
      if (!appConfig.mode) setConfigMissing(true);
      if (appConfig.mode === 'managed_by_cs') {
        const url = new URL(window.location.href);
        const getTokenFromBackend = async () => {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_API}/v1/exchangeToken`, {
            token: url.searchParams.get("app-token"),
          });
          setToken(response.data.data.token as string);
          setConfigLoading(false)
        };
        getTokenFromBackend().catch(() => setErrorMessage('The servers are encountering a significant increase in traffic.'));

      } else {
        setConfigLoading(false)
      }
    })()
  }, [appSdk])

  const setFieldData = useCallback(
    async (data: unknown) => {
      setLoading(true);
      await location.field.setData(data);
      setEntryField(data);
      setLoading(false);
    },
    [location, setLoading, setEntryField]
  );

  const getToken = useCallback(
    () => token, [token]
  )

  if (configLoading)
    return <LoadingScreen showMessage={false} />;

  if (configMissing) {
    return <AppConfigMissing appInstallationUID={appSdk?.installationUID} />;
  }

  return (
    <EntryFieldExtensionContext.Provider value={{ entryField, setFieldData, loading, appMetadata, getToken, errorMessage, setErrorMessage }}>
      {children}
    </EntryFieldExtensionContext.Provider>
  );
};