import { useCallback, useEffect, useState } from "react";
import { useAppLocation } from "../hooks/useAppLocation";
import { AppConfigurationExtensionContext, InstallationData } from "../contexts/appConfigurationExtensionContext";
import { useAppSdk } from "../hooks/useAppSdk";

export const AppConfigurationExtensionProvider = ({ children }: any) => {
  const [installationData, setInstallation] = useState<InstallationData>({
    configuration: {},
    serverConfiguration: {},
  });
  const [loading, setLoading] = useState<boolean>(false);
  const appSdk = useAppSdk()
  const { location } = useAppLocation();

  useEffect(() => {
    if (!appSdk) return
    const initialiser = async () => {
      var appConfigWidget = appSdk.location.AppConfigWidget;
      const config = await appConfigWidget?.installation.getInstallationData();
      setInstallation((config as any))
      setLoading(false);
    }
    initialiser()
  }, [appSdk])

  const setInstallationData = useCallback(
    async (data: { [key: string]: any }) => {
      setLoading(true);
      const newInstallationData: InstallationData = {
        configuration: { ...installationData.configuration, ...data },
        serverConfiguration: installationData.serverConfiguration,
      };
      await location.installation.setInstallationData(newInstallationData);
      setInstallation(newInstallationData);
      setLoading(false);
    },
    [location, setInstallation, setLoading]
  );

  return (
    <AppConfigurationExtensionContext.Provider value={{ installationData, setInstallationData, loading }}>
      {children}
    </AppConfigurationExtensionContext.Provider>
  );
};
