import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { MarketplaceAppProvider } from "../../common/providers/MarketplaceAppProvider";
import { EntryFieldExtensionProvider } from "../../common/providers/EntryFieldExtensionProvider";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import PageNotFound from "../404/404";
import { AppConfigurationExtensionProvider } from "../../common/providers/AppConfigurationExtensionProvider";

/**
 * All the routes are Lazy loaded.
 * This will ensure the bundle contains only the core code and respective route bundle
 * improving the page load time
 */
const AppConfigurationExtension = React.lazy(() => import("../ConfigScreen/NewAppConfiguration"));
const EntryFieldExtension = React.lazy(() => import("../EntryField/EntryField"));

function App() {
  return (
    <ErrorBoundary>
      <MarketplaceAppProvider>
        <Routes>
          <Route
            path="/app-configuration"
            element={
              <Suspense fallback={<LoadingScreen showMessage={false} />}>
                <AppConfigurationExtensionProvider>
                  <AppConfigurationExtension />
                </AppConfigurationExtensionProvider>
              </Suspense>
            }
          />
          <Route
            path="/field-modifier"
            element={
              <Suspense fallback={<LoadingScreen showMessage={false} />}>
                <EntryFieldExtensionProvider>
                  <EntryFieldExtension />
                </EntryFieldExtensionProvider>
              </Suspense>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>

      </MarketplaceAppProvider>
    </ErrorBoundary >
  );
}

export default App;
