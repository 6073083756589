import { Link } from "@contentstack/venus-components";
import { ReactComponent as ConfigMissing } from "../assets/missingconfig.svg"

/**
 * AppConfigMissing component.
 * This components will be rendered if the App config is missing to initialize.(ContentStack SDK)
 */
export const AppConfigMissing = ({ appInstallationUID }) => {
  return (
    <div className="app-config-missing-container">
      <div className="config-missing-main">
        <div className="config-missing-icon">
          <ConfigMissing />
        </div>
        <div className="config-missing-title">
          <h4>App not configured</h4>
        </div>
        <div className="config-missing-description">
          Marketplace AI Assistant App is not configured properly. Please try installing the app again.
        </div>
        <div className="config-missing-redirect mt-1">
          <Link type="external" fontColor="link" href={`${process.env.REACT_APP_HOST_BASE_URL}/#!/marketplace/installed-apps/${appInstallationUID}/configuration`}>App Configuration</Link>
        </div>
      </div>
    </div>
  )
};
