import React from "react";
import { ChatGPTAppMetadataType } from "../types/AppMetadata";

export type EntryFieldExtensionContextType = {
  entryField: unknown;
  setFieldData: (data: unknown) => void;
  loading: boolean;
  getToken: () => string;
  appMetadata: ChatGPTAppMetadataType | null;
  errorMessage: string | null;
  setErrorMessage: (error: string | null) => void;
};

export const EntryFieldExtensionContext = React.createContext<EntryFieldExtensionContextType>({
  entryField: null,
  setFieldData: () => {},
  loading: false,
  appMetadata: null,
  getToken: () => "",
  errorMessage: null,
  setErrorMessage: () => {},
});
