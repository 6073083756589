const localAppMetadata = {
    "_version": 54,
    "locale": "en-us",
    "uid": "blt169555ec6824b6ac",
    "ACL": {},
    "_in_progress": false,
    "created_at": "2023-03-06T15:29:09.646Z",
    "created_by": "blt84c7dbee95981303",
    "field_specific_prompt": {
        "json_rte": ""
    },
    "generate_from_other_fields": [
        {
            "title": "Outline",
            "_metadata": {
                "uid": "cs8287640d5de2511a"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "bltdf5af3ca4fb3d1f8",
                "uid": "bltb3185ada2cfb5919",
                "created_by": "blt84c7dbee95981303",
                "updated_by": "blt84c7dbee95981303",
                "created_at": "2023-03-06T15:45:52.974Z",
                "updated_at": "2023-03-06T15:45:52.974Z",
                "content_type": "image/svg+xml",
                "file_size": "682",
                "filename": "Custom_Field.svg",
                "title": "Custom_Field.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-03-06T15:50:21.466Z",
                    "user": "blt84c7dbee95981303"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltb3185ada2cfb5919/64060ab0c448032f744a8415/Custom_Field.svg"
            },
            "prompt": "Generate an outline from this text: {{field_data}}",
            "testid": "generate-outline"
        },
        {
            "title": "Headline",
            "_metadata": {
                "uid": "cse0c23377e2dd4f39"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "bltdf5af3ca4fb3d1f8",
                "uid": "bltb3185ada2cfb5919",
                "created_by": "blt84c7dbee95981303",
                "updated_by": "blt84c7dbee95981303",
                "created_at": "2023-03-06T15:45:52.974Z",
                "updated_at": "2023-03-06T15:45:52.974Z",
                "content_type": "image/svg+xml",
                "file_size": "682",
                "filename": "Custom_Field.svg",
                "title": "Custom_Field.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-03-06T15:50:21.466Z",
                    "user": "blt84c7dbee95981303"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltb3185ada2cfb5919/64060ab0c448032f744a8415/Custom_Field.svg"
            },
            "prompt": "Write an SEO-enhanced headline without double quotes for this text: {{field_data}}",
            "testid": "generate-headline"
        },
        {
            "title": "Summary",
            "_metadata": {
                "uid": "cs1593b8f642f8c5fc"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "bltdf5af3ca4fb3d1f8",
                "uid": "bltb3185ada2cfb5919",
                "created_by": "blt84c7dbee95981303",
                "updated_by": "blt84c7dbee95981303",
                "created_at": "2023-03-06T15:45:52.974Z",
                "updated_at": "2023-03-06T15:45:52.974Z",
                "content_type": "image/svg+xml",
                "file_size": "682",
                "filename": "Custom_Field.svg",
                "title": "Custom_Field.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-03-06T15:50:21.466Z",
                    "user": "blt84c7dbee95981303"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltb3185ada2cfb5919/64060ab0c448032f744a8415/Custom_Field.svg"
            },
            "prompt": "Generate a summary from this text: \n{{field_data}}",
            "testid": "generate-summary"
        },
        {
            "title": "SEO Tags",
            "_metadata": {
                "uid": "cs3cd0457d2bf34855"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "bltdf5af3ca4fb3d1f8",
                "uid": "bltb3185ada2cfb5919",
                "created_by": "blt84c7dbee95981303",
                "updated_by": "blt84c7dbee95981303",
                "created_at": "2023-03-06T15:45:52.974Z",
                "updated_at": "2023-03-06T15:45:52.974Z",
                "content_type": "image/svg+xml",
                "file_size": "682",
                "filename": "Custom_Field.svg",
                "title": "Custom_Field.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-03-06T15:50:21.466Z",
                    "user": "blt84c7dbee95981303"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltb3185ada2cfb5919/64060ab0c448032f744a8415/Custom_Field.svg"
            },
            "prompt": "Generate 5 to 10 SEO tags in comma-separated format from this text: \n{{field_data}}",
            "testid": "generate-seo-tags"
        },
        {
            "title": "Persona tags",
            "_metadata": {
                "uid": "cs7ff61ea0f1758a48"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "bltdf5af3ca4fb3d1f8",
                "uid": "bltb3185ada2cfb5919",
                "created_by": "blt84c7dbee95981303",
                "updated_by": "blt84c7dbee95981303",
                "created_at": "2023-03-06T15:45:52.974Z",
                "updated_at": "2023-03-06T15:45:52.974Z",
                "content_type": "image/svg+xml",
                "file_size": "682",
                "filename": "Custom_Field.svg",
                "title": "Custom_Field.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-03-06T15:50:21.466Z",
                    "user": "blt84c7dbee95981303"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltb3185ada2cfb5919/64060ab0c448032f744a8415/Custom_Field.svg"
            },
            "prompt": "Generate several key persona tags in this format: \"one, two, three, four\"\n\nFrom this text: {{field_data}}",
            "testid": ""
        },
        {
            "title": "Blog Post",
            "_metadata": {
                "uid": "cs4165d3fa62c3b35a"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "bltdf5af3ca4fb3d1f8",
                "uid": "bltb3185ada2cfb5919",
                "created_by": "blt84c7dbee95981303",
                "updated_by": "blt84c7dbee95981303",
                "created_at": "2023-03-06T15:45:52.974Z",
                "updated_at": "2023-03-06T15:45:52.974Z",
                "content_type": "image/svg+xml",
                "file_size": "682",
                "filename": "Custom_Field.svg",
                "title": "Custom_Field.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-03-06T15:50:21.466Z",
                    "user": "blt84c7dbee95981303"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltb3185ada2cfb5919/64060ab0c448032f744a8415/Custom_Field.svg"
            },
            "prompt": "Generate a 3-paragraph SEO-enhanced blog post from this text: \n{{field_data}}",
            "testid": "generate-blog-post"
        }
    ],
    "model_configuration": {
        "max_tokens": 1500,
        "model": "gpt-3.5-turbo",
        "temperature": 0.75,
        "top_p": null
    },
    "operation_on_current_field": [
        {
            "title": "Re-write this text",
            "_metadata": {
                "uid": "csa7baf16815bf7d27"
            },
            "tooltip": "Re-phrase the existing content",
            "icon": {
                "parent_uid": "bltdf5af3ca4fb3d1f8",
                "uid": "bltf8c1ac6056eff11b",
                "created_by": "blt84c7dbee95981303",
                "updated_by": "blt84c7dbee95981303",
                "created_at": "2023-03-06T15:44:10.921Z",
                "updated_at": "2023-03-06T15:44:10.921Z",
                "content_type": "image/svg+xml",
                "file_size": "615",
                "filename": "Reset_Forward.svg",
                "title": "Reset_Forward.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-03-06T15:50:21.489Z",
                    "user": "blt84c7dbee95981303"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltf8c1ac6056eff11b/64060a4a4fd99f36ebe22f3c/Reset_Forward.svg"
            },
            "prompt": "Rephrase this text to enhance SEO and make it approximately the same length {{json_rte_prompt}}: {{field_data}}",
            "testid": "rewrite-this-text"
        },
        {
            "title": "Shorten this text",
            "_metadata": {
                "uid": "cs840e01029a67a1ed"
            },
            "tooltip": "Rephrase the content with a shorter length",
            "icon": {
                "parent_uid": "bltdf5af3ca4fb3d1f8",
                "uid": "blt7875b059e12ba9ef",
                "created_by": "blt84c7dbee95981303",
                "updated_by": "blt84c7dbee95981303",
                "created_at": "2023-03-06T15:45:52.982Z",
                "updated_at": "2023-03-06T15:45:52.982Z",
                "content_type": "image/svg+xml",
                "file_size": "1842",
                "filename": "Expand_Collapse.svg",
                "title": "Expand_Collapse.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-03-06T15:50:21.514Z",
                    "user": "blt84c7dbee95981303"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt7875b059e12ba9ef/64060ab03acc576c5603452b/Expand_Collapse.svg"
            },
            "prompt": "Shorten this text by 25% {{json_rte_prompt}}: \n{{field_data}}",
            "testid": "shorten-this-text"
        },
        {
            "title": "Translate to current locale language",
            "_metadata": {
                "uid": "cs5e5597a17e69004f"
            },
            "tooltip": "Translate this field into language of this current locale.",
            "icon": {
                "parent_uid": "bltdf5af3ca4fb3d1f8",
                "uid": "blt32b9a14a057c2603",
                "created_by": "blt84c7dbee95981303",
                "updated_by": "blt84c7dbee95981303",
                "created_at": "2023-03-08T15:12:04.800Z",
                "updated_at": "2023-03-08T15:12:04.800Z",
                "content_type": "image/svg+xml",
                "file_size": "1424",
                "filename": "Translation.svg",
                "title": "Translation.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-03-08T15:41:36.850Z",
                    "user": "blt84c7dbee95981303"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt32b9a14a057c2603/6408a5c4aeefb86bfe4b5217/Translation.svg"
            },
            "prompt": "Translate this text into {{current_locale}} {{json_rte_prompt}}: {{field_data}}",
            "testid": "translate-to-current-locale"
        }
    ],
    "section_1": "Replace field content",
    "section_2": "Generate based on another field",
    "tags": [
        "content intelligence",
        "static strings"
    ],
    "title": "App Metadata",
    "updated_at": "2023-06-29T07:08:57.628Z",
    "updated_by": "blt96247f2c2802e6f4",
    "publish_details": {
        "environment": "blt6203b467e841f88e",
        "locale": "en-us",
        "time": "2023-06-29T07:09:15.431Z",
        "user": "blt96247f2c2802e6f4"
    }
}; exports.default = localAppMetadata;